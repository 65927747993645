import React, { useState, useEffect, useRef, SyntheticEvent  } from 'react';

import './App.css';
import moment from 'moment'

interface ISlidePageProps
{
     imageUrl:string,
     cacheIdentifier:string,
     alt:string,
     title:string,
     lastModified:string,
     showDiagnosticInfo:boolean,
     maxDisplayWidthPercentage:number
}

const SlidePage = (props:ISlidePageProps) => {

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const onImageLoaded = (event:SyntheticEvent<HTMLImageElement>) => {
    //console.log('image is loaded event');
    setImageLoaded(true);
  }

  useEffect(() => {
    setImageLoaded(false);
  }, [props.imageUrl]); //no array -> only on start


  //so it's calculated on each re-render (but I think it's fast)
  let effectivePercentage = props.maxDisplayWidthPercentage;
  if (effectivePercentage === null || 
      effectivePercentage === undefined || 
      effectivePercentage < 20 || 
      effectivePercentage > 100) 
  {
      effectivePercentage = 100;
  }

  let margin = (100 - effectivePercentage)/2;
  let classToApplynex = `w-[${effectivePercentage}%] ml-[${margin}%] mr-[${margin}%] object-contain min-h-0 justify-center`;

  let parentDivClass = 'h-screen flex flex-col';
  
  //This did work, but the page is just white for a moment, which is not nice -> we'll mange it from the parent..
  if(!imageLoaded){
    parentDivClass += ' invisible'
  }
  
  //console.log(parentDivClass);


  /*let dohundred = false;
  let classToApply = "w-[100%] ml-[0%] mr-[0%] object-contain min-h-0 justify-center";
  if (!dohundred){
    classToApply = "w-[60%] ml-[20%] mr-[20%] object-contain min-h-0 justify-center"
  }

  console.log(classToApply);*/

  return (
      
          <div className={parentDivClass}>
            <div className='text-4xl bold mt-3 justify-center'>{props.title}</div>
            <div style={{fontSize:10}} className='mt-3 justify-center'>Modified on {props.lastModified}</div>
            {props.imageUrl !== "" && 
            // we could try sth like this
            //https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image

              <img  className={classToApplynex} 
                    src={props.imageUrl}
                    alt={props.alt} 
                    key={props.imageUrl}
                    onLoad={onImageLoaded} 
                  />
                }
          </div>
    );
 
}

export default SlidePage;
