import React, { /*Component,*/ useRef, useState, useEffect  } from 'react';

import {PlayListDto} from './Dto/PlayListDto';
import './App.css';
import {httpDirectResult, httpDirectResultWithBody} from './Service/httpCall';
import  config  from './config';

import Player from './Player';
import RegistrationPage from './RegistrationPage';
import DiagnosticsPage, {IDiagnoticsPageProps} from './DiagnosticsPage';
import './App.css';


const App = () => {

  enum State {
    Loading = 1,
    Register,
    Diagnostics,
    Player,
    Error
  }
  const [state, setState] = useState<State>(State.Loading);

  useEffect(() => {
    initialFetchDataAsync(); //just launch the request, do not use async within useeffect
  }, []); 

  const diagnosticsData = useRef<IDiagnoticsPageProps>();
  const uniqueIdRef = useRef<string>("");
  const registrationCodeRef = useRef<string>("");
  const timeoutForPlayer = useRef<NodeJS.Timeout|null>(null);
  const timeoutForRetry = useRef<NodeJS.Timeout|null>(null);
  const retries = useRef<number>(0);

  const initialFetchDataAsync = async () => {
    try {
      let uniqueId = localStorage.getItem("uniqueId");
      
      let playListDto = await httpDirectResultWithBody<PlayListDto>(`${config.backEndApi}/api/GetSlides`, uniqueId);
      registrationCodeRef.current = playListDto.registrationCode;
      console.log(playListDto.registrationCode);
      if (playListDto.isRegistered) {
        uniqueIdRef.current = uniqueId as string;
        diagnosticsData.current = {tenantName:playListDto.tenantName, displayId:playListDto.displayId, displayName:playListDto.displayName, playListName:playListDto.playListName, allowCommercialPage:playListDto.allowCommercialPage };
        setState(State.Diagnostics);
        timeoutForPlayer.current = setInterval(
          () => tickForPlayer(),
          5000
        );

      } else {
        localStorage.setItem("uniqueId", playListDto.uniqueId); // might have been updated
        uniqueIdRef.current = playListDto.uniqueId;
        setState(State.Register);
      }
    } catch (error) {
      console.log(error);
      setState(State.Error);
      timeoutForRetry.current = setInterval(
        () => tickForRetry(),
        5000
      );

    }
  }

  const tickForPlayer= () =>{
    setState(State.Player);
    if (timeoutForPlayer.current != null){
      clearInterval(timeoutForPlayer.current);
    }
  }

  const tickForRetry= () =>{
    setState(State.Loading);
    retries.current++; // is this bad?; I forgot
    //stop timer; it will be started if it goes wrong again
    if (timeoutForRetry.current != null){
      clearInterval(timeoutForRetry.current);
    }
    initialFetchDataAsync();
    
  }


  let body = <div>Unknown state</div>
  if (state === State.Loading){
    body = <div>LOADING...</div>
  }
  else if (state === State.Register)  {
    body = <RegistrationPage registrationCode={registrationCodeRef.current}/>
  }
  else if (state === State.Diagnostics && diagnosticsData.current != null) {
    body = <DiagnosticsPage tenantName={diagnosticsData.current.tenantName} displayId={diagnosticsData.current.displayId} displayName={diagnosticsData.current.displayName} playListName={diagnosticsData.current.playListName} allowCommercialPage={diagnosticsData.current.allowCommercialPage} />
    
  }
  else if (state === State.Player)  {
    body = <Player uniqueId={uniqueIdRef.current} />
  }
  else if (state === State.Error)  {
    body = <div>Error (attempt {retries.current})</div>
  }

    return (
      <div className="h-screen w-screen text-center">
        {body}
      </div>
    );
  
}

export default App;
