import React, {   } from 'react';
import './App.css';

const CommercialPage = () => {
    return (
      <div className='grid grid-cols-1 grid-rows-4 bg-blue h-screen place-items-center '>
        <div className='text-4xl'>Powered by</div>
        <div className='font-serif text-8xl'>IonScreen</div>
        <div className='text-4xl'>Securely display your online dashboards and webcontent</div>
        <div>
          <div className='text-5xl'>Visit</div>
          <div className='text-5xl text-blue-600'>https://www.ionscreen.online</div>
        </div>
      </div>
    );
  
}

export default CommercialPage;
