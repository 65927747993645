import React, { useState, useEffect, useRef  } from 'react';
import moment from 'moment'
import  config  from './config';

export interface IDiagnoticsPageProps
{
     tenantName:string;
     displayId:number;
     displayName:string;
     playListName:string;
     allowCommercialPage:boolean;
}

const DiagnosticsPage = (theprops:IDiagnoticsPageProps) => {

    return <div>
        <h1>Diagnostics info</h1>
        <div className="">Tenant: {theprops.tenantName}</div>
        <div>Display id: {theprops.displayId}</div>
        <div>Display name: {theprops.displayName}</div>
        <div>Playlist: {theprops.playListName}</div>
        <div>Show commercialpage: {theprops.allowCommercialPage?"true":"false"}</div>
        <br/>
        <div>Utc-time: {moment.utc().toString()}</div>
        <div>Local-time: {moment().toString()}</div>
        <br/>
        <div>Build number: {config.buildNumber}</div>
        
    </div>
}

export default DiagnosticsPage
