import axios, {AxiosRequestConfig} from 'axios';
export interface IHttpResponse<T> extends Response {
    parsedBody?: T;
  }
  
  export const httpCall = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
    return new Promise((resolve, reject) => {
      let response: IHttpResponse<T>;
      fetch(request)
        .then(res => {
          response = res;
          return res.json();
        })
        .then(body => {
          if (response.ok) {
            response.parsedBody = body;
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const httpPatch = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
    return new Promise((resolve, reject) => {
      let response: IHttpResponse<T>;
      fetch(request, {method: 'PATCH'})
        .then(res => {
          response=res;
          if (res.ok) {
            response.parsedBody = undefined;
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const myhttpPatch = <T>(url:string, data:T): Promise<IHttpResponse<T>> => {
    return new Promise((resolve, reject) => {
      let response: IHttpResponse<T>;
      fetch(url, {
        method: 'PATCH', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        })
        .then(res => {
          response=res;
          if (res.ok) {
            response.parsedBody = undefined;
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const httpDirectResult = <T>(request: RequestInfo): Promise<T> => {
    return new Promise((resolve, reject) => {
      fetch(request)
        .then(response => response.json())
        .then(body => {
          resolve(body);
        })
        .catch(err => {
          reject(err);
        });
    });
  };


  interface IDisplatDataDto {
    uniqueId:string|null
  }

  export const httpDirectResultWithBody = async <T>(requestUrl:string, uniqueId:string|null): Promise<T> => {

     let a:IDisplatDataDto = {
      uniqueId: uniqueId
    };

    let axiosRequestConfig:AxiosRequestConfig = {};
    //axiosRequestConfig.json= JSON.stringify(a);
    
  
    let res = await axios.post(requestUrl, JSON.stringify(a), axiosRequestConfig);
    return res.data as T;
  };


  