import React, { useState, useEffect, useRef  } from 'react';

interface IRegistrationPageProps
{
     registrationCode:string
}

const RegistrationPage = (theprops:IRegistrationPageProps) => {
    const [registrationCode, setRegistrationCode] = useState<string>(theprops.registrationCode);

    return <>
        <br/>
        <br/>
        <br/>
        <div className="text-2xl font-bold">This display-device is not linked to any display yet. Use the below registrationcode to register this display on https://app.ionscreen.online</div>
        <br/>
        <div className="text-3xl">{registrationCode}</div>
        <br/>
        <br/>
        <br/>
        Once the registation is done, restart the device. Or refresh the page if you are using a browser.
    </>
}

export default RegistrationPage